<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="10" sm="12" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Établissement.DIRECTION" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular"
              ><v-row class="align-center justify-space-between no-gutters">
                <div>Détails de la partie DIRECTION de l'établissement</div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-progress-circular
                      :rotate="90"
                      :size="55"
                      :width="7"
                      :value="rate"
                      :color="rateColor"
                      v-on="on"
                      ><div class="caption font-weight-regular secondary--text">
                        {{ rate }}%
                      </div></v-progress-circular
                    >
                  </template>
                  <span>Taux de remplissage</span>
                </v-tooltip>
              </v-row>
            </v-card-title>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom digital</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field 
                    dense 
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>
            
            <!-- le nom interne -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom interne</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field 
                    dense 
                    v-model="internalName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom commercial -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    nom commercial
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="commercialName"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le statut -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">statut</div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <v-autocomplete
                    :items="statusLabels"
                    item-text="label"
                    item-value="label"
                    v-model="status"
                    no-data-text="aucun statut"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la structure juridique -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    structure juridique
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <v-autocomplete
                    :items="legalStructures"
                    item-text="digitalName"
                    item-value="digitalName"
                    v-model="legalStructure"
                    no-data-text="aucune structure juridique"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- email -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">email</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="email"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le métier -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">métier</div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <v-autocomplete
                    :items="metiers"
                    item-text="name"
                    v-model="metier"
                    no-data-text="aucun métier"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les activités / sous-activités -->
            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular mt-5">
                    les activités / sous-activités
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <!-- Combo de sélection d'un nouvelle activité -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedActivity"
                        :items="availableActivities"
                        item-text="name"
                        return-object
                        placeholder="Associer une activité"
                        hint="Associer une activité puis appuyer sur le bouton '+'"
                        clearable
                        no-data-text="aucune activité"
                        v-on:keyup.enter="addActivity(selectedActivity)"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        :disabled="!selectedActivity"
                        @click="addActivity(selectedActivity)"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>

                  <!-- Présentation des ensembles Activités et sous-activité -->
                  <div
                    v-for="(activity, i) in activitiesSubActivities"
                    :key="'A' + i"
                  >
                    <v-row no-gutters align="center" justify="space-between">
                      <!-- Nom de l'activité -->
                      <div class="mt-2 font-weight-black">
                        {{ activity.name }}
                      </div>
                      <!-- Supprime une activité UNIQUEMENT s'il n'y a pas de sous-activité -->
                      <v-btn
                        icon
                        color="primary"
                        :disabled="activity.subActivities != 0"
                        @click="removeActivity(activity)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>

                    <!-- Affiche le site web de l'activité pour cet établissement -->
                    <div class="mr-2 ml-8">Site web :</div>
                    <div class="mb-1 ml-16 ma-0 pa-0">
                      <v-col cols="11" class="pa-0 ma-0">
                        <v-text-field
                          class="font-weight-light"
                          dense
                          v-model="activity.website"
                        >
                        </v-text-field>
                      </v-col>
                    </div>

                    <!-- Affiche le nom commercial de l'activité pour cet établissement -->
                    <div class="mr-2 ml-8">Nom commercial :</div>
                    <div class="mb-1 ml-16 ma-0 pa-0">
                      <v-col cols="11" class="pa-0 ma-0">
                        <v-text-field
                          class="font-weight-light"
                          dense
                          v-model="activity.commercialName"
                        >
                        </v-text-field>
                      </v-col>
                    </div>

                    <!-- Affiche le téléphone de l'accueil de l'activité pour cet établissement -->
                    <div class="mr-2 ml-8">
                      Téléphone accueil établissement :
                    </div>
                    <div class="mb-1 ml-16 ma-0 pa-0">
                      <v-col cols="11" class="pa-0 ma-0">
                        <v-text-field
                          class="font-weight-light"
                          dense
                          v-model="activity.phoneReception"
                        >
                        </v-text-field>
                      </v-col>
                    </div>

                    <!-- Affiche le téléphone du call-center de l'activité pour cet établissement -->
                    <div class="mr-2 ml-8">Téléphone call-center :</div>
                    <div class="mb-1 ml-16 ma-0 pa-0">
                      <v-col cols="11" class="pa-0 ma-0">
                        <v-text-field
                          class="font-weight-light"
                          dense
                          v-model="activity.phoneCallCenter"
                        >
                        </v-text-field>
                      </v-col>
                    </div>

                    <!-- Affiche le téléphone du call-center de l'activité pour cet établissement -->
                    <div class="mr-2 ml-8">Id Google My Business :</div>
                    <div class="mb-1 ml-16 ma-0 pa-0">
                      <v-col cols="11" class="pa-0 ma-0">
                        <v-text-field
                          class="font-weight-light"
                          dense
                          v-model="activity.idGoogleMyBusiness"
                        >
                        </v-text-field>
                      </v-col>
                    </div>

                    <!-- Les sous-activités de l'activité de l'établissement  -->
                    <div class="mr-2 ml-8">Sous-activités :</div>
                    <div
                      v-for="(subActivity, j) in activity.subActivities"
                      :key="'B' + j"
                    >
                      <v-row
                        no-gutters
                        align="center"
                        justify="space-between"
                        class="mb-1 ml-16"
                      >
                        <!-- Nom de la sous-activité -->
                        <div>{{ subActivity.digitalName }}</div>
                        <!-- Suppression de la sous-activité -->
                        <v-btn
                          icon
                          color="primary"
                          @click="removeSubActivity(activity, subActivity)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-row>
                    </div>
                    <div>
                      <div class="ml-16 d-flex align-center">
                        <v-autocomplete
                          :items="availablesSubActivities(activity)"
                          v-model="subActSelected"
                          item-text="digitalName"
                          multiple
                          return-object
                          placeholder="Préciser des sous-activités"
                          hint="Préciser des sous-activités puis appuyer sur le bouton '+'"
                          clearable
                          no-data-text="aucune sous-activité"
                        >
                        </v-autocomplete>
                        <v-btn
                          color="primary"
                          icon
                          :disabled="subActSelected.length == 0"
                          @click="addSubActivities(activity)"
                          ><v-icon>mdi-plus</v-icon></v-btn
                        >
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'adresse -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    adresse / rue
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="address"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le complément d'adresse -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    adresse / complément d'adresse
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="address2"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le code postal -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    adresse / code postal
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="postalCode"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la ville -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    adresse / ville
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="city"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La longitude et la latitude -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="space-between" align-self="center">
                  <div class="capitalize font-weight-regular mb-3 py-2">
                    adresse / latitude
                  </div>

                  <div class="capitalize font-weight-regular mb-2 py-2">
                    adresse / longitude
                  </div>
                </v-col>
                <v-col cols="6" align-self="center" class="pr-4">
                  <v-text-field dense v-model="latitude"></v-text-field>
                  <v-text-field dense v-model="longitude"></v-text-field>
                </v-col>

                <v-col cols="2" justify="center" align="right">
                  <v-btn
                    outlined
                    class="ma-2 px-4 py-6 btn no-text-transform"
                    color="primary"
                    @click="openMap"
                    height="60px"
                    :disabled="!canViewMap"
                  >
                    <div class="capitalize">voir sur la carte</div>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le département -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">département</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="departement"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le support -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    support par défaut
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <v-autocomplete
                    :items="supports"
                    v-model="support"
                    item-text="digitalName"
                    item-value="id"
                    clearable
                    no-data-text="aucun support"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les supports fonction -->
            <v-card-text>
              <v-row no-gutters align="start">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">
                    Supports spécifiques
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <div
                    v-for="(support, i) in associateEstablishmentFunction"
                    :key="i"
                    v-show="associateEstablishmentFunction.length > 0"
                  >
                    <v-row
                      no-gutters
                      align="start"
                      justify="space-between"
                      class="mb-1"
                    >
                      <v-col cols="6">
                        <div>{{ support.supportName }}</div>
                      </v-col>
                      <v-col cols="5">
                        <div>{{ support.functionName }}</div>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          icon
                          color="primary"
                          @click="removeSupportFunction(support)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn></v-col
                      >
                    </v-row>
                  </div>
                  <!-- Affichage de la liste des établissement et fonctions associables -->
                  <div>
                    <v-row no-gutters justify="space-between" align="start">
                      <v-col cols="6" class="px-1">
                        <v-autocomplete
                          v-model="selectedEstabSupport"
                          :items="allEstablishmentNames"
                          item-text="digitalName"
                          return-object
                          placeholder="Associer un établissement support"
                          hint="Associer un établissement support."
                          clearable
                          class="ma-0 pa-0"
                          no-data-text="aucun établissement"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="5" class="px-1">
                        <v-autocomplete
                          v-model="selectedFunctionSupport"
                          :items="availableFunctionSupport"
                          item-text="name"
                          return-object
                          placeholder="Associer une fonction support"
                          hint="Associer une fonction support, puis appuyer sur le bouton '+'"
                          clearable
                          class="ma-0 pa-0"
                          no-data-text="aucune fonction"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          color="primary"
                          icon
                          @click="addSupportFunction()"
                          :disabled="!canAddSupport"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le tèl fixe -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">tel fixe</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="phoneMain"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le tèl standard -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    tel établissement / standard
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="phoneReception"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le tèl d'astreinte -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    tel astreinte
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="phoneOncall"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le fax -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">fax</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="fax"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le site web -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">site</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="website"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les destinataires des offres d'emploi -->
            <v-card-text>
              <v-row no-gutters align="start">
                <v-col cols="4" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">
                    Contacts locaux pour les offres d'emploi
                  </div>
                </v-col>
                <v-col cols="8" align-self="start">
                  <div
                    v-for="(recipient, i) in associatedJobOfferRecipients"
                    :key="i"
                    v-show="associatedJobOfferRecipients.length > 0"
                  >
                    <v-row
                      no-gutters
                      align="start"
                      justify="space-between"
                      class="mb-1"
                    >
                      <v-col cols="6">
                        <div>{{ recipient.label }}</div>
                      </v-col>
                      <v-col cols="5">
                        <div>{{ recipient.email }}</div>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          icon
                          color="primary"
                          @click="removeJobOfferRecipient(recipient)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn></v-col
                      >
                    </v-row>
                  </div>
                  <!-- Saisie -->
                  <div>
                    <v-row no-gutters justify="space-between" align="start">
                      <v-col cols="6" class="px-1">
                        <v-text-field
                          ref="newJobOfferRecipientLabel"
                          dense
                          label="Ajouter un destinataire"
                          v-model="newJobOfferRecipient.label"
                          :rules="[
                            (value) => notExistingJobOfferRecipientLabel(value),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5" class="px-1">
                        <v-text-field
                          type="email"
                          autocomplete="false"
                          dense
                          label="email"
                          v-model="newJobOfferRecipient.email"
                          v-on:keyup.enter="addJobOfferRecipient()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          color="primary"
                          icon
                          @click="addJobOfferRecipient()"
                          :disabled="!canAddJobOfferRecipient"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le code urssaf -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">Code URSSAF (Centre de versement)</div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field dense v-model="urssafCode"></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- RAW -->
            <v-card-text v-for="(field, i) in rawFields" :key="'C' + i">
              <v-row no-gutters align="center">
                <v-col cols="4" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    {{ field.label }}
                  </div>
                </v-col>
                <v-col cols="8" align-self="center">
                  <v-text-field
                    dense
                    v-model="field.data"
                    @input="field.changed(field)"
                    :error="field.empty"
                    append-outer-icon="mdi-pencil-off"
                    @click:append-outer="field.setNA(field)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">enregistrer</div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-layout column class="fab-container">
      <v-btn
        class="mx-2 my-2"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToTop"
        color="primary"
        @click="toTop"
      >
        <v-icon :disabled="!canScrollToTop">mdi-chevron-up</v-icon>
      </v-btn>

      <v-btn
        class="mx-2 my-1"
        fab
        elevation="0"
        v-scroll="onScroll"
        :disabled="!canScrollToBottom"
        @click="toBottom"
        color="primary"
      >
        <v-icon :disabled="!canScrollToBottom">mdi-chevron-down</v-icon>
      </v-btn>
    </v-layout>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- Fenetre modale pour la visualisation/modification de la géoloc de l'établissement -->
    <DialogMap
      :visible.sync="showDialogMap"
      :addressProp="address"
      :postalCodeProp="postalCode"
      :cityProp="city"
      :latitude="latitude"
      :longitude="longitude"
      :onlyReadMode="false"
      @confirmed="onDialogMap"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import DialogMap from "../dialogs/dialogMap.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { areTheSame, trimString } from "@/tools/string_tool.js";

import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import { EstablishmentStatusService } from "@/service/etablishment/establishment_status_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";
import { JobOfferRecipientService } from "@/service/etablishment/job_offer_recipient_service.js";

import PropertiesMapping from "@/service/etablishment/properties_mapping.js";

import * as exceptions from "@/service/exception_to_message.js";
import * as logger from "@/tools/logger.js";

import * as stringTools from "@/tools/string_tool.js";
import * as emailTools from "@/tools/email_tool.js";
import { checkUrl } from "@/tools/url_tool.js";

const _HYPERLINK = "HYPERLINK";
const _FIELD_NAME_NUM_ASSURANCE = "numeroContratAssuranceEtablissement";
const _FIELD_NAME_LINK_NUM_ASSURANCE = "lienVersNumeroContrat";

export default {
  name: "EditEstablishment",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
    DialogMap,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** les services. */
      serviceEstablishments: null,
      serviceEstablishmentsStatus: null,
      serviceActivities: null,
      serviceMetiers: null,
      serviceLegalStructure: null,
      serviceFunction: null,
      serviceJobOfferRecipient: null,

      /** l'identifiant à éditer */
      establishmentId: null,

      /**La donnée originale */
      source: null,
      digitalName: null,
      commercialName: null,
      internalName: null,
      address: null,
      address2: null,
      city: null,
      latitude: null,
      longitude: null,
      postalCode: null,
      departement: null,
      status: null,
      support: null,
      phoneMain: null,
      phoneReception: null,
      phoneOncall: null,
      fax: null,
      /**les établissements */
      supports: [],
      email: null,
      website: null,
      urssafCode: null,
      statusLabels: [],
      /** Tableau des activités d'un établissement */
      sourceActivityIds: [],
      /** Tableau des sous-activités d'un établissement */
      sourceSubActivityIds: [],
      /** Tableau des ensembles activité - url d'un établissement */
      sourceActivitiesUrls: [],

      /** La structure juridique sélectionnée */
      legalStructure: null,
      /** la liste des structures juridique */
      legalStructures: [],

      /**le métier sélectionné */
      metier: null,
      /**les métiers pour la combobox */
      metiers: [],

      /** Tous les ensembles activités / sousActivités de la bd */
      allActivitiesSubActivities: [],

      /** les activités / sous-activité de la vue */
      activitiesSubActivities: [],
      /** L'activité sélectionnée dans la combobox */
      selectedActivity: null,

      /** Les sous-activité sélectionnée */
      subActSelected: [],

      /** L'ensemble des fonctions de la base */
      allFunctions: [],
      /** L'ensemble des établissement (id + nom digital) */
      allEstablishmentNames: [],
      /** Les couples source establishment/function */
      sourceEstablishmentFunction: [],
      /** Les estab/function associés visuellement */
      associateEstablishmentFunction: [],
      /** l'établissement sélectionné */
      selectedEstabSupport: null,
      /** La fonction sélectionné */
      selectedFunctionSupport: null,
      /** Les destinataires d'offres d'emploi en base*/
      sourceJobOfferRecipients: [],
      /** Les destinataires d'offres d'emploi de la vue */
      associatedJobOfferRecipients: [],
      newJobOfferRecipient: { label: null, email: null },

      rawFields: [],

      /**le raw initial */
      raw: [],

      /**Boutons pour scroller tout en bas et tout en haut  */
      canScrollToTop: false,
      canScrollToBottom: true,

      /** Etat de visualisation de la carte */
      showDialogMap: false,

      rules: {
        validate: (value) =>
          checkUrl(value) || "veuillez saisir une url valide",
      },
    };
  },
  methods: {
    async load() {
      try {
        logger.debug("Load ressource on Edit establishment.");
        this.loading = true;

        /** Récupération de l'ensemble des établissements (id + nom digital) */
        this.supports = await this.serviceEstablishments.getDigitalNames();
        this.allEstablishmentNames = JSON.parse(JSON.stringify(this.supports));

        //on ajoute les labels des statuts des établissements
        this.statusLabels = await this.serviceEstablishmentsStatus.getAll();

        // Récupération de l'établissement cible
        const entity = await this.serviceEstablishments.getById(
          this.establishmentId
        );

        // Récupération de la liste des structures juridiques
        this.legalStructures = await this.serviceLegalStructure.getAll();
        /** Matching structure juridique avec avec celui de l'établisssment */
        this.legalStructure = this.legalStructures.find(
          (l) => l.id == entity.legalStructure
        );

        let entitySupport = null;
        if (entity.support) {
          entitySupport = await this.serviceEstablishments.getById(
            entity.support
          );
        }
        this.support = entitySupport;

        this.metiers = await this.serviceMetiers.getAll();

        //si l'établissement a un métier, on met le bon dans la combobox
        if (entity.metierId) {
          this.metier = this.metiers.find((m) => m.id == entity.metierId);
        } else {
          this.metier = null;
        }

        await this.loadActivities(entity);

        // Récupération de l'ensemble des fonctions
        this.allFunctions = await this.serviceFunction.getAll();
        // Récupération de l'ensemble des couples Estab/fonction
        this.sourceEstablishmentFunction =
          await this.serviceEstablishments.getAllEstablishmentFunctionForEstablishment(
            this.establishmentId
          );

        // Boucle pour ajouter le nom de l'estab
        for (const estab of this.sourceEstablishmentFunction) {
          // Récupération du nom de l'établissement
          const estabName = this.allEstablishmentNames.find(
            (e) => e.id == estab.supportId
          );
          // Récupération de la fonction
          const func = this.allFunctions.find((f) => f.id == estab.functionId);

          // Attribution des données lisibles à l'objet
          estab.supportName = estabName.digitalName;
          estab.functionName = func.name;
        }
        // Copie de la source dans le tableau d'affichage
        this.associateEstablishmentFunction = JSON.parse(
          JSON.stringify(this.sourceEstablishmentFunction)
        );

        this.sourceJobOfferRecipients =
          await this.serviceJobOfferRecipient.getAll(this.establishmentId);
        // Copie de la source dans le tableau d'affichage
        this.associatedJobOfferRecipients = JSON.parse(
          JSON.stringify(this.sourceJobOfferRecipients)
        );

        //on charge le RAW  l'établissement cible
        this.raw = await this.serviceEstablishments.getRawById(
          this.establishmentId
        );

        if (!this.raw) {
          this.raw = {};
        }

        // conserver l'original
        this.source = JSON.parse(JSON.stringify(entity));

        this.init();
        this.initRaw();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async loadActivities(entity) {
      // réponse pour 1 establishment : [ idactivity1, idActivity2], [idSub11, idSub12, idSub23]
      this.sourceActivityIds = entity.activityIds;
      this.sourceSubActivityIds = entity.subActivityIds;

      // Récupère tous les activités de la base
      const allActivities = await this.serviceActivities.getActivities();
      // Récupère un tableau d'activité avec chacune ses sous-activités
      const activitiesWithSub =
        await this.serviceActivities.getActivitiesWithSubactivities();
      // Boucle sur chaque activité récupéré pour charger ses sous-activités
      for (const activity of allActivities) {
        // Recherche de l'activité dans le tableau
        const found = activitiesWithSub.find(
          (a) => a.activityId == activity.id
        );
        let subActivities = {};
        if (found) {
          subActivities = found.subActivities;
        }

        activity.subActivities = [];
        activity.subActivities = subActivities;

        activity.website = "";
        activity.commercialName = "";
        activity.phoneReception = "";
        activity.phoneCallCenter = "";
        activity.idGoogleMyBusiness = "";
      }
      // On met toute la bd activité et sous-activités
      this.allActivitiesSubActivities = allActivities;

      // On filtre les données pour afficher les activités de l'établissement
      const activities = JSON.parse(
        JSON.stringify(
          this.allActivitiesSubActivities.filter((a) =>
            this.sourceActivityIds.find((act) => act == a.id)
          )
        )
      );

      // Récupération des ensembles activité - urls de l'établissement
      let activitiesDetails = [];
      activitiesDetails = await this.serviceEstablishments.getUrlOFActivities(
        entity.id
      );
      this.sourceActivitiesUrls = [...activitiesDetails];

      // Boucle de chargement des sous-activités qui matches avec celles de l'établissement
      for (const activity of activities) {
        const subs = activity.subActivities.filter((sub) =>
          this.sourceSubActivityIds.find((s) => s == sub.id)
        );
        activity.subActivities = subs;

        // chargement des sites web des activités
        const activityDetail = activitiesDetails.find(
          (u) => u.activityId == activity.id
        );
        if (activityDetail) {
          activity.website = activityDetail.website || "";
          activity.commercialName = activityDetail.commercialName || "";
          activity.phoneReception = activityDetail.phoneReception || "";
          activity.phoneCallCenter = activityDetail.phoneCallCenter || "";
          activity.idGoogleMyBusiness = activityDetail.idGoogleMyBusiness || "";
        } else {
          activity.website = "";
          activity.commercialName = "";
          activity.phoneReception = "";
          activity.phoneCallCenter = "";
          activity.idGoogleMyBusiness = "";
        }
      }
      this.activitiesSubActivities = activities;
    },

    /** Initialisation des datas de l'établissement */
    init() {
      this.digitalName = this.source.digitalName;
      this.commercialName = this.source.commercialName;
      this.internalName = this.source.internalName;
      if (this.source.address) {
        this.address = this.source.address.address;
        this.address2 = this.source.address.address2;
        this.postalCode = this.source.address.postalCode;
        this.city = this.source.address.city;
        this.latitude = this.source.address.latitude;
        this.longitude = this.source.address.longitude;
      } else {
        this.address = null;
        this.address2 = null;
        this.postalCode = null;
        this.city = null;
        this.latitude = null;
        this.longitude = null;
      }

      this.departement = this.source.departement;
      this.status = this.source.status;
      this.email = this.source.email;
      this.phoneMain = this.source.phoneMain;
      this.phoneReception = this.source.phoneReception;
      this.phoneOncall = this.source.phoneOncall;
      this.fax = this.source.fax;

      this.website = this.source.website;
      this.urssafCode = this.source.urssafCode;
    },

    /** Initialisation des datas de la partie RAW (la partie non structurée) */
    getRawFields() {
      const tool = new PropertiesMapping();
      const mapping = tool.getMapping();
      const fields = [];

      mapping.forEach((m) => {
        if (m.isRaw) {
          // Création d'un champ pour la propriétée mappée
          fields.push({
            label: m.label,
            property: m.property,
            order: m.order,
            hint: m.hint,
            isRaw: m.isRaw,
            na: false,
            data: "",
          });
          // Si m est 'contrat d'assurance' on ajoute le champ pour la saisie du lien.
          if (m.property === _FIELD_NAME_NUM_ASSURANCE) {
            const link = tool.getLinkContratNumber();
            fields.push({
              label: link.label,
              property: link.property,
              order: link.order,
              hint: link.hint,
              isRaw: link.isRaw,
              na: false,
              data: "",
            });
          }
        }
      });

      let order = 1000;

      //   on ajoute les propriétés orphelines de RAW qui ne sont pas mappées
      for (const property in this.raw) {
        if (!fields.find((f) => f.property == property)) {
          fields.push({
            label: property,
            property: property,
            order: order++,
            hint: "",
            isRaw: true,
            na: false,
            data: "",
          });
        }
      }
      return fields;
    },
    initRaw() {
      const fields = this.getRawFields();

      // pour chaque champs à afficher
      fields.forEach((e) => {
        e.na = false;
        // raw is null ?
        if (this.raw) {
          // le champ est le lien vers le num contrat, on récupère la data hyperlink
          if (e.property === _FIELD_NAME_LINK_NUM_ASSURANCE) {
            e.data = this.splitHyperlink(
              this.raw[_FIELD_NAME_NUM_ASSURANCE]
            ).link;
          } else if (this.raw[e.property]) {
            // Si c'est la propriéé du numero de contrat de travail, on récupère le texte.
            if (e.property === _FIELD_NAME_NUM_ASSURANCE) {
              // Récupération du texte
              e.data = stringTools.replaceNullOrUndefineByHyphen(
                this.splitHyperlink(this.raw[e.property]).text
              );
            } else {
              e.data = stringTools.replaceNullOrUndefineByHyphen(
                this.raw[e.property]
              );
            }
          }
          // si champ à n/a, activaton
          if (e.data === "n/a") {
            e.na = true;
          }

          e.changed = (f) => {
            f.empty = stringTools.isNullOrEmpty(f.data);
          };

          e.setNA = (f) => {
            if (f.data != "n/a") {
              f.data = "n/a";
              f.empty = false;
            } else {
              f.data = null;
              f.empty = true;
            }
          };
        }
      });

      this.rawFields = fields;
    },

    // Sauvegarde de l'ensemble des datas de l'établissement
    async save() {
      try {
        this.loading = true;
        const entity = JSON.parse(JSON.stringify(this.source));
        entity.digitalName = this.digitalName;
        entity.commercialName = this.commercialName;
        entity.internalName = this.internalName;

        // si l'établissement a un métier, on enregistre le lien (id)
        if (this.metier) {
          entity.metierId = this.metier.id;
        } else {
          entity.metierId = null;
        }
        // l'adresse de l'établissement
        entity.address = {
          address: this.address,
          address2: this.address2,
          postalCode: this.postalCode,
          city: this.city,
          latitude: Number(this.latitude),
          longitude: Number(this.longitude),
        };
        entity.departement = this.departement;
        entity.status = this.status;

        /** Affectation de la structure juridique */
        entity.legalStructure = this.legalStructure.id;
        entity.support = null;
        if (this.support) {
          entity.support = this.support.id;
        }
        entity.email = this.email;
        entity.phoneMain = this.phoneMain;
        entity.phoneReception = this.phoneReception;
        entity.phoneOncall = this.phoneOncall;
        entity.fax = this.fax;

        // Récupération de l'ensemble des ids
        entity.activityIds = this.activitiesSubActivities.map((a) => a.id);

        const ids = [];
        // Récupération de la liste des ids des sous-activités affichés
        for (const activity of this.activitiesSubActivities) {
          const subIds = activity.subActivities.map((s) => s.id);
          ids.push(...subIds);
        }
        entity.subActivityIds = ids;

        // Modif au 22/01/2021 remplacé par l'url sur chaque activité
        //entity.website = this.website;
        entity.website = "";
        entity.urssafCode = this.urssafCode;

        /******* Sauvegarde des datas de l'établissements en base */
        await this.serviceEstablishments.update(entity);

        /** Enregistrement des urls d'activité */
        for (const activity of this.activitiesSubActivities) {
          const actUrl = this.sourceActivitiesUrls.find(
            (u) => u.activityId == activity.id
          );
          // on crée l'objet
          const url = {
            activityId: activity.id,
            website: activity.website,
            commercialName: activity.commercialName,
            phoneReception: activity.phoneReception,
            phoneCallCenter: activity.phoneCallCenter,
            idGoogleMyBusiness: activity.idGoogleMyBusiness,
          };

          // Si l'activité n'est pas trouvé, on créé le tuple activité url
          if (!actUrl) {
            await this.serviceEstablishments.createUrlActivity(entity.id, url);
          }
          // Si l'activité existe on vérifie que l'url n'est pas été mise à jour
          else if (actUrl.website != activity.url) {
            await this.serviceEstablishments.updateUrlActivity(entity.id, url);
          }
        }

        // Enregistrement des supports (couple établissement/fonction)
        await this.saveSupports();

        // Enregistrement des destinataires pour les offres d'emploi
        if (this.jobOfferRecipientHasChanged) {
          await this.serviceJobOfferRecipient.updateAll(
            this.establishmentId,
            this.associatedJobOfferRecipients
          );
        }

        // Enregistrement des données du raw
        await this.saveRawDatas(entity);

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error("MY ERROR" + error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    async saveSupports() {
      // Parcours des supports affichés
      for (const support of this.associateEstablishmentFunction) {
        // Recherche par fonction dans la source du support
        const found = this.sourceEstablishmentFunction.find(
          (s) => s.functionId == support.functionId
        );

        // non trouvé --> c'est un ajout
        // trouvé --> diff des datas
        if (!found) {
          const create = {};
          create.establishmentId = this.establishmentId;
          create.functionId = support.functionId;
          create.supportId = support.supportId;

          // Sauvegarde du couple support
          await this.serviceEstablishments.createSupportForEstablishment(
            this.establishmentId,
            create
          );
        } else {
          if (support.supportId != found.supportId) {
            const update = {};
            update.establishmentId = this.establishmentId;
            update.functionId = support.functionId;
            update.supportId = support.supportId;

            // Mise à jour du couple support
            await this.serviceEstablishments.updateSupportForEstablishment(
              this.establishmentId,
              support.functionId,
              update
            );
          }
        }
      }
      // Parcours des supports source
      for (const source of this.sourceEstablishmentFunction) {
        // Recherche par fonction dans la source du support
        const found = this.associateEstablishmentFunction.find(
          (s) => s.functionId == source.functionId
        );

        // Non trouvé, il y a donc eu une suppression
        if (!found) {
          await this.serviceEstablishments.deleteSupportForEstablishment(
            this.establishmentId,
            source.functionId
          );
        }
      }
    },

    async saveRawDatas(entity) {
      const rawEntity = {};

      // Parcours les champs de la partie non structurée et met à jour
      this.rawFields.forEach((field) => {
        const property = field.property;

        const foundField = this.rawFields.find((f) => f.property == property);

        if (property === _FIELD_NAME_NUM_ASSURANCE) {
          const text = this.joinHyperlink(
            foundField.data,
            this.rawFields.find(
              (f) => f.property === _FIELD_NAME_LINK_NUM_ASSURANCE
            ).data
          );

          rawEntity[property] = text;
        } else if (property === _FIELD_NAME_LINK_NUM_ASSURANCE) {
          logger.debug(""); //pas de traitement particulier, on écarte le champs
        } else {
          rawEntity[property] = field.data;
        }
      });

      // Modification du raw, il devient un objet
      const rawSave = {
        raw: JSON.stringify(rawEntity),
      };
      await this.serviceEstablishments.updateRaw(entity.id, rawSave);
    },

    onScroll(e) {
      if (typeof window === "undefined") return;
      const currentPos = window.pageYOffset || e.target.scrollTop || 0;
      const bottom = document.body.scrollHeight - window.innerHeight;
      this.canScrollToTop = currentPos > bottom * 0.15;
      this.canScrollToBottom = currentPos < bottom * 0.85;
    },

    toTop() {
      this.$vuetify.goTo(0);
    },

    toBottom() {
      this.$vuetify.goTo(document.body.scrollHeight);
    },

    /** Ajouter une activité */
    addActivity(activity) {
      if (!activity) return;

      // Clonage de l'activité à ajouter puis on vide les sous-activités
      const activityTemp = { ...activity };
      activityTemp.subActivities = [];

      const foundDetail = this.sourceActivitiesUrls.find(
        (u) => u.activityId == activityTemp.id
      );
      if (foundDetail) {
        activityTemp.website = foundDetail.website;
        activityTemp.commercialName = foundDetail.commercialName;
        activityTemp.phoneReception = foundDetail.phoneReception;
        activityTemp.phoneCallCenter = foundDetail.phoneCallCenter;
        activityTemp.idGoogleMyBusiness = foundDetail.idGoogleMyBusiness;
      }

      // Ajout de la nouvelle activité à la vue
      this.activitiesSubActivities.push(activityTemp);

      this.selectedActivity = null;
    },
    /** Suppression d'une activité de la vue */
    removeActivity(activity) {
      if (activity.subActivities != 0) return;

      /** Recherche l'index de l'objet dans le tableau des activités de la vue puis le supprime */
      const index = this.activitiesSubActivities.findIndex(
        (a) => a.id == activity.id
      );
      this.activitiesSubActivities.splice(index, 1);
    },

    /** Ajout d'une sous-activité au sein d'une activité */
    addSubActivities(activity) {
      //Ajoute le tableau des éléments sélectionnés au tableau des sous-activités affichés
      activity.subActivities.push(...this.subActSelected);

      this.subActSelected = [];
    },

    /** Suppression d'une sous-activité */
    removeSubActivity(activity, subActivity) {
      if (!activity || !subActivity) return;

      const index = activity.subActivities.findIndex(
        (s) => s.id == subActivity.id
      );
      activity.subActivities.splice(index, 1);
    },

    /** Suppression d'un couple estab fonction */
    removeSupportFunction(support) {
      this.associateEstablishmentFunction.splice(
        this.associateEstablishmentFunction.findIndex(
          (e) =>
            e.supportId == support.supportId &&
            e.functionId == support.functionId
        ),
        1
      );
    },

    /** Ajout d'un couple establishment function */
    addSupportFunction() {
      // Fabrication de l'objet à ajouter.
      const support = {};
      support.establishmentId = this.establishmentId;
      support.supportId = this.selectedEstabSupport.id;
      support.supportName = this.selectedEstabSupport.digitalName;
      support.functionId = this.selectedFunctionSupport.id;
      support.functionName = this.selectedFunctionSupport.name;

      // Ajout au support associé
      this.associateEstablishmentFunction.push(support);
      this.selectedEstabSupport = null;
      this.selectedFunctionSupport = null;
    },

    addJobOfferRecipient() {
      if (!this.canAddJobOfferRecipient) return;
      const newRecipient = {
        label: this.newJobOfferRecipient.label,
        email: this.newJobOfferRecipient.email,
      };
      this.associatedJobOfferRecipients.push(newRecipient);
      this.newJobOfferRecipient = { label: null, email: null };
      this.$refs.newJobOfferRecipientLabel.focus();
    },
    removeJobOfferRecipient(jobOfferRecipient) {
      this.associatedJobOfferRecipients.splice(
        this.associatedJobOfferRecipients.findIndex(
          (e) =>
            e.label == jobOfferRecipient.label &&
            e.email == jobOfferRecipient.email
        ),
        1
      );
    },
    notExistingJobOfferRecipientLabel(label) {
      if (label) {
        const sameLabel =
          this.associatedJobOfferRecipients.findIndex(
            (e) => e.label === label
          ) != -1;
        if (sameLabel) {
          return "Destinataire déjà existant";
        }
      }
      return true;
    },

    /** Splitte le lien hyperlink donné en paramètre en 2 donnée : texte et lien */
    splitHyperlink(link) {
      let text = "";
      let linkField = "";

      if (link) {
        // si la data contient hyperlink
        if (link.includes(_HYPERLINK)) {
          const split = link.split('"');

          text = split[3];
          linkField = split[1];
        } else {
          // s'il n'y a pas "HYPERLINK" le texte est le link
          text = link;
          if (text === "n/a") {
            linkField = text;
          }
        }
      }
      // Retour d'un objet contenant le texte et le link.
      return { text: text, link: linkField };
    },

    /** Reassocie le lien hyperlink */
    joinHyperlink(text, link) {
      const start = '=HYPERLINK("';
      const between = '";"';
      const end = '")';

      if (!text) {
        text = "";
      }
      if (!link) {
        link = "";
      }

      if (text === "" && link === "") {
        return "";
      } else if (
        (text === "" && link === "n/a") ||
        (text === "n/a" && link === "") ||
        (text === "n/a" && link === "n/a")
      ) {
        return "n/a";
      } else if (link === "" || link === "n/a") {
        return text;
      } else if (text === "n/a") {
        text = "";
      }

      return start + link + between + text + end;
    },

    /** la liste des sous-activités disponibles dans la comboBox pour une activité */
    availablesSubActivities(activity) {
      let items = [];
      // Récupération de l'activité dans l'object source
      const activitySource = this.allActivitiesSubActivities.find(
        (a) => a.id == activity.id
      );
      // Récupération de la liste des ids des sous-activités déjà affichées
      const map = activity.subActivities.map((s) => s.id);

      // Tri des items déjà affichés
      items = activitySource.subActivities.filter((s) => !map.includes(s.id));

      return items;
    },

    detailsActivityHasChanged(detail1, detail2) {
      let changed = false;
      if (!areTheSame(detail1.website, detail2.website)) {
        changed = true;
      }
      if (!areTheSame(detail1.commercialName, detail2.commercialName)) {
        changed = true;
      }
      if (!areTheSame(detail1.phoneReception, detail2.phoneReception)) {
        changed = true;
      }
      if (!areTheSame(detail1.phoneCallCenter, detail2.phoneCallCenter)) {
        changed = true;
      }
      if (!areTheSame(detail1.idGoogleMyBusiness, detail2.idGoogleMyBusiness)) {
        changed = true;
      }
      return changed;
    },

    /** Ouverture de la fenetre pour la géoloc de l'adresse de l'établissement */
    openMap() {
      this.showDialogMap = true;
    },
    /** Retour du composant carte  */
    onDialogMap(item) {
      this.latitude = item.newLatitude;
      this.longitude = item.newLongitude;
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    completed() {
      return this.digitalName && this.status && this.legalStructure;
    },

    /** Vérifie si une des informations de la vue as changé */
    hasIdentityChanged() {
      if (!areTheSame(this.source.digitalName, this.digitalName.trim())) {
        logger.debug("le nom digital a changé");
        return true;
      }

      if (!areTheSame(this.source.commercialName, this.commercialName)) {
        logger.debug("le nom commercial a changé");
        return true;
      }

      if (!areTheSame(this.source.internalName, this.internalName)) {
        logger.debug("le nom interne a changé");
        return true;
      }

      if (!areTheSame(this.source.status, this.status)) {
        logger.debug("le status a changé");
        return true;
      }
      /** Comparaison de la structure juridique sélectionnée avec celle de la source */
      if (this.source.legalStructure != this.legalStructure.id) {
        logger.debug("la structure légale a changé");
        return true;
      }

      if (!areTheSame(this.source.phoneMain, this.phoneMain)) {
        logger.debug("le tel fixe a changé");
        return true;
      }
      if (!areTheSame(this.source.phoneReception, this.phoneReception)) {
        logger.debug("le tel du standard a changé");
        return true;
      }
      if (!areTheSame(this.source.phoneOncall, this.phoneOncall)) {
        logger.debug("le telephone d'astreinte a changé");
        return true;
      }
      if (!areTheSame(this.source.fax, this.fax)) {
        logger.debug("le fax a changé");
        return true;
      }
      if (!areTheSame(this.source.email, this.email)) {
        return true;
      }

      if (!areTheSame(this.source.departement, this.departement)) {
        return true;
      }

      if (!areTheSame(this.source.urssafCode, this.urssafCode)) {
        return true;
      }

      //le métier
      const metierId = this.metier ? this.metier.id : null;
      const sourceMetierId = this.source.metierId ? this.source.metierId : null;
      if (metierId !== sourceMetierId) {
        logger.debug("changement :" + `${metierId} & ${sourceMetierId}`);
        return true;
      }
      return false;
    },
    hasActivitySectionChanged() {
      // A-t-on ajouté ou supprimé une activité
      if (this.activityHasChanged) {
        logger.debug("activity has changed ok");
        return true;
      }
      // A-t-on ajouté ou supprimé une sous-activité
      if (this.subActivitiesHasChanged) {
        logger.debug("sub-activity has changed ok");
        return true;
      }
      // A-t-on ajouté ou changé une url d'une activité
      if (this.detailsActivitiesHasChanged) {
        logger.debug("URL ACTIVITY HAS CHANGED");
        return true;
      }
      return false;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      if (this.hasIdentityChanged) {
        return true;
      }

      if (this.addressHasChanged) {
        return true;
      }

      if (this.support) {
        if (this.source.support != this.support.id) {
          return true;
        }
      } else {
        if (this.source.support) {
          return true;
        }
      }

      if (this.hasActivitySectionChanged) {
        return true;
      }

      // As-t-on ajouté ou supprimé des couples établissement fonction
      if (this.supportHasChanged) {
        logger.debug("specific supports has changed");
        return true;
      }

      if (this.jobOfferRecipientHasChanged) {
        logger.debug("job offer recipients has changed");
        return true;
      }

      return this.rawHasChanged;
    },

    rawHasChanged() {
      for (const property in this.raw) {
        const f = this.rawFields.find((field) => field.property == property);

        if (property === _FIELD_NAME_NUM_ASSURANCE) {
          const text = this.joinHyperlink(
            f.data,
            this.rawFields.find(
              (field) => field.property === _FIELD_NAME_LINK_NUM_ASSURANCE
            ).data
          );

          const original = this.raw["numeroContratAssuranceEtablissement"];

          if (!areTheSame(original, text)) {
            return true;
          }
        } else if (property === _FIELD_NAME_LINK_NUM_ASSURANCE) {
          continue;
        } else {
          if (this.raw[property] !== f.data) {
            console.error("property changed  " + property);
            return true;
          }
        }
      }
      return false;
    },
    addressHasChanged() {
      if (this.source.address) {
        if (this.existingAddressHasChanged) {
          return true;
        }
      } else {
        if (this.newAddressHasChanged) {
          return true;
        }
      }
      return false;
    },
    newAddressHasChanged() {
      return (
        this.address ||
        this.postalCode ||
        this.city ||
        this.latitude ||
        this.longitude
      );
    },
    existingAddressHasChanged() {
      if (!areTheSame(this.source.address.address, this.address)) {
        logger.debug("le address a changé");
        return true;
      }

      if (!areTheSame(this.source.address.address2, this.address2)) {
        logger.debug("le complément d'adresse a changé");
        return true;
      }

      if (!areTheSame(this.source.address.postalCode, this.postalCode)) {
        logger.debug("le postalcode a changé");
        return true;
      }

      if (!areTheSame(this.source.address.city, this.city)) {
        logger.debug("le city a changé");
        return true;
      }
      if (!areTheSame(this.source.address.latitude, this.latitude)) {
        logger.debug("la latitude a changé");
        return true;
      }
      if (!areTheSame(this.source.address.longitude, this.longitude)) {
        logger.debug("la longitude a changé");
        return true;
      }
      return false;
    },

    /** Recherche si une activité a été ajoutée ou supprimé */
    activityHasChanged() {
      let changed = false;
      // Pour chaque activités de la vue, on regarde si elle est dans la source
      this.activitiesSubActivities.forEach((ra) => {
        const found = this.sourceActivityIds.find((rb) => rb == ra.id);
        if (!found) {
          changed = true;
        }
      });

      if (changed) {
        return true;
      }

      // Pour chaque activités de la source, on regarde si elle est dans la vue
      this.sourceActivityIds.forEach((ra) => {
        const found = this.activitiesSubActivities.find((rb) => rb.id == ra);
        if (!found) {
          changed = true;
        }
      });
      return changed;
    },

    /** Recherche si une sous-activité a été ajoutée ou supprimé */
    subActivitiesHasChanged() {
      let changed = false;
      const ids = [];

      // Récupération de la liste des ids des sous-activités affichés
      for (const activity of this.activitiesSubActivities) {
        const subIds = activity.subActivities.map((s) => s.id);
        ids.push(...subIds);
      }
      // Copie de la source des ids des sous-activités
      const source = [];
      source.push(...this.sourceSubActivityIds);

      // Comparaison des chaines textes des tableau triés
      if (JSON.stringify(source.sort()) != JSON.stringify(ids.sort())) {
        changed = true;
      }

      return changed;
    },

    /** Recherche de modification sur les details des activités */
    detailsActivitiesHasChanged() {
      let changed = false;

      for (let activitySubActivity of this.activitiesSubActivities) {
        if (changed) break;

        const found = this.sourceActivitiesUrls.find(
          (rb) => rb.activityId == activitySubActivity.id
        );

        // activité trouvée dans la source --> onvérifie si les datas ont été changées
        if (found) {
          changed = this.detailsActivityHasChanged(activitySubActivity, found);
        } else {
          // Activité nouvelle, on vérifie si un des champs a été renseigné
          if (activitySubActivity.website != "") {
            changed = true;
          }
          if (activitySubActivity.commercialName != "") {
            changed = true;
          }
          if (activitySubActivity.phoneReception != "") {
            changed = true;
          }
          if (activitySubActivity.phoneCallCenter != "") {
            changed = true;
          }
        }
      }

      return changed;
    },

    jobOfferRecipientHasChanged() {
      if (
        this.associatedJobOfferRecipients.length !=
        this.sourceJobOfferRecipients.length
      ) {
        return true;
      }
      for (const recipient of this.associatedJobOfferRecipients) {
        const found = this.sourceJobOfferRecipients.find(
          (sr) => sr.label == recipient.label && sr.email == recipient.email
        );
        if (!found) {
          return true;
        }
      }
      for (const recipient of this.sourceJobOfferRecipients) {
        const found = this.associatedJobOfferRecipients.find(
          (sr) => sr.label == recipient.label && sr.email == recipient.email
        );
        if (!found) {
          return true;
        }
      }
      return false;
    },

    /** Vérifie l'ensemble des supports spécifiques couples estab/fonction */
    supportHasChanged() {
      let changed = false;

      // Analyse les couples estab/fonction affichées et les compare à la source
      this.associateEstablishmentFunction.forEach((sa) => {
        // Recherche de l'entité identique dans la source.
        // Recherche par l'id de la fonction puisque celle-ci doit être unique.
        const found = this.sourceEstablishmentFunction.find(
          (sb) => sb.functionId == sa.functionId
        );

        // Si on as trouvé, on vérifie que l'estab soit identique
        if (found) {
          if (sa.supportId != found.supportId) {
            changed = true;
          }
        } else {
          changed = true;
        }
      });

      // S'il y a eu du changement, aucuns besoin d'analyse dans l'autre sens
      if (changed) {
        return true;
      }

      // Analyse inverse à la précédente, on vérifie la source par rapport à la vue
      this.sourceEstablishmentFunction.forEach((sa) => {
        // Recherche de l'entité identique dans la vue affichage
        // Recherche par l'id de la fonction puisque celle-ci doit être unique.
        const found = this.associateEstablishmentFunction.find(
          (sb) => sb.functionId == sa.functionId
        );

        // Si on as trouvé, on vérifie que l'estab soit identique
        if (found) {
          if (sa.supportId != found.supportId) {
            changed = true;
          }
        } else {
          changed = true;
        }
      });

      return changed;
    },

    /**Retourne la couleur à afficher en fonction du taux de remplissage. */
    rateColor() {
      return "primary";
    },
    /**calculer le taux de remplissage pour les données brutes */
    computeDataRate() {
      if (this.loading) {
        return 0.0;
      }

      const count = 20;
      let filled = 0;

      try {
        //les items : address, city, postalCode, digitalName,commercialName, internalName, status, support, email, departement
        filled += this.computeIdentityRate;
        filled += this.computeAddressRate;

        if (this.activitiesSubActivities.length > 0) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(this.departement)) {
          filled++;
        }
        if (this.support) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(this.phoneMain)) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(this.phoneReception)) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(this.phoneOncall)) {
          filled++;
        }
        if (!stringTools.isNullOrEmpty(this.fax)) {
          filled++;
        }
      } catch (error) {
        console.error("problème taux de remplissage (data)" + error);
        filled = 0;
      }

      return { count, filled };
    },
    computeIdentityRate() {
      let filled = 0;
      if (!stringTools.isNullOrEmpty(this.digitalName)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(this.commercialName)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(this.internalName)) {
        filled++;
      }
      if (this.status) {
        filled++;
      }
      if (this.legalStructure) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(this.email)) {
        filled++;
      }
      if (this.metier) {
        filled++;
      }
      return filled;
    },
    computeAddressRate() {
      let filled = 0;
      if (!stringTools.isNullOrEmpty(this.address)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(this.postalCode)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(this.city)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(this.latitude)) {
        filled++;
      }
      if (!stringTools.isNullOrEmpty(this.longitude)) {
        filled++;
      }
      return filled;
    },
    /**calculer le taux de remplissage pour les données RAW */
    computeRawRate() {
      if (this.loading) {
        return 0.0;
      }

      const count = this.rawFields.length;
      let missings = 0;
      try {
        missings = this.rawFields.filter((field) =>
          stringTools.isNullOrEmpty(field.data)
        ).length;
      } catch (error) {
        console.error("problème taux de remplissage (raw)" + error);
        missings = count;
      }

      return { count, filled: count - missings };
    },
    rate() {
      const dataRate = this.computeDataRate;
      const rawRate = this.computeRawRate;

      const count = dataRate.count + rawRate.count;
      const filled = dataRate.filled + rawRate.filled;

      if (count > 0) {
        return (100 * (filled / count)).toFixed(1);
      } else {
        return 0.0;
      }
    },

    /** la liste des activités disponibles dans la combobox */
    availableActivities() {
      let availables = [];

      const ids = this.activitiesSubActivities.map((a) => a.id);

      availables = this.allActivitiesSubActivities.filter(
        (a) => !ids.includes(a.id)
      );

      return availables;
    },

    /** la liste des fonction disponible pour la combobox */
    availableFunctionSupport() {
      let availables = [];

      const ids = this.associateEstablishmentFunction.map((f) => f.functionId);

      availables = this.allFunctions.filter((f) => !ids.includes(f.id));

      return availables;
    },

    /** Peut ajouter le support */
    canAddSupport() {
      return this.selectedEstabSupport && this.selectedFunctionSupport;
    },

    /** Vérifie si la saisie d'un destinataire pour les offres d'emploi est valide ou pas */
    canAddJobOfferRecipient() {
      if (stringTools.isNullOrEmpty(this.newJobOfferRecipient.label)) {
        return false;
      }
      if (stringTools.isNullOrEmpty(this.newJobOfferRecipient.email)) {
        return false;
      }
      if (!emailTools.isEmailValid(this.newJobOfferRecipient.email)) {
        return false;
      }
      const sameLabel =
        this.associatedJobOfferRecipients.findIndex(
          (e) => e.label === this.newJobOfferRecipient.label
        ) != -1;
      return !sameLabel;
    },

    /** Possibilité de voir la carte */
    canViewMap() {
      return (
        (this.address && this.postalCode && this.city) ||
        (this.latitude && this.longitude)
      );
    },
  },
  watch: {},
  mounted() {
    this.serviceEstablishments = new EstablishmentsService(this.$api);
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());
    this.serviceEstablishmentsStatus = new EstablishmentStatusService(
      this.$api.getEstablishmentStatusApi()
    );
    this.serviceLegalStructure = new LegalStructureService(
      this.$api.getLegalStructuresApi()
    );
    this.serviceFunction = new FonctionService(this.$api.getFunctionApi());
    this.serviceJobOfferRecipient = new JobOfferRecipientService(
      this.$api.getJobOfferRecipientApi()
    );

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.establishmentId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
.fab-container {
  margin: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
